#supportwidget-wrapper {
    border-radius: 6px;
    z-index: 2147483647;
    width: 384px;
    position: fixed;
    right: 30px;
    bottom: 68px;
    bottom: calc(20px + 80px);
    height: calc(100% - 84px);
    min-height: 288px;
    max-height: 624px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
    transition: height 0.3s ease-in;
    overflow-x: hidden !important;
    overflow-y: scroll;
}

#supportwidget-btn {
    right: 22px;
    bottom: 22px;
    border: none;
    position: fixed;
    min-width: 104px;
    max-width: 156px;
    height: 56px;
    z-index: 2147483000;
    visibility: visible;
}

#support-notify .notification {
    z-index: 2147483648;
}

.card-form-wrapper{
    margin-top: -70px;
}

.cmp-text-area .form-group .text-area {
    border: none;
    border-bottom: 1px solid #5a768a;
    resize: none;
    font-size: 1rem;
}
.form-group {
    margin-bottom: 2rem;
}

.screenshot-wrapper {
    max-height: 168px;
}
.screenshot-wrapper   .screenshot-close{
    border-radius: 50%;
    z-index: 1000;
    right: 10px;
    height: 24px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.2);
}


.screenshot-img {
    transform: scale(1.05, 1.15);
}

.file-uploader-box{
    border-style: dashed;
}

.pointer {
    cursor: pointer;
}

.justify-self-end {
    justify-self: end;
}

.card.card-teaser .file-card {
    align-items: center;
}

/* .file-name {
    line-height: 1.1rem;
} */

.file-size {
    line-height: 0.9rem;
    font-size: 0.8rem;
}